require('./active_group_by_buttons');
require('./active_nav');
require('./add_prescriptions');
require('./create_url_from_checked_arretes.js');
require('./loader');
require('./modal');
require('./position_elements_with_dynamic_height');
require('./prescription_textarea.js');
require('./search_classement');
require('./search_installation');
require('./select_all_checkbox');
require('./topic_selector.js');
require('./add_matomo_tag.js');
